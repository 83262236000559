import React from 'react'

import Image from '../components/image'
import Layout from '../components/layout'

import styles from './research.module.scss'

const Research = ({ pageContext: { fields: { heroImage, title, date }, html, authors } }) => {
  // console.log(authors)
  return (
    <Layout title={title}>
      <article className={styles.blog}>
        <figure className={styles.blog__hero}>
          <Image className={styles.heroImage} src={heroImage.publicURL} alt={title} />
        </figure>
        <div className={styles.blog__info}>
          <h1>{title}</h1>
          <h3>{date}</h3>
        </div>
        <div className={styles.blog__body} dangerouslySetInnerHTML={{ __html: html }} />
        <div className={styles.blog__footer}>
          <h2>
            Written by {authors.map((author, i) => (
              i > 0 ? (
                <span key={i}>, {author.frontmatter.title}</span>
              ) : (
                <span key={i}>{author.frontmatter.title}</span>
              )
            ))}
          </h2>
        </div>
      </article>
    </Layout>
  )
}

export default Research
